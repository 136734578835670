@use 'node_modules/@ngneat/hot-toast/src/styles/styles.scss';
@import '~@vscode/codicons/dist/codicon.css';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/* You can add global styles to this file, and also import other style files */
body {
  // background-color: #EDF3F8;
  background-color: #edf3f8;
  margin-bottom: 24px;
}

.ant-affix {
  background-color: #edf3f8;
  border-bottom: 1px solid #c6dcec;
  box-shadow: 0px 15px 15px 0px #fff;
  clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
}

.ant-layout-content {
  padding: 0 2%;
}

.ant-page-header {
  padding-bottom: 24px;
}

.toolbar {
  width: 100%;
  padding: 0px;
  border-bottom: 1px solid #edf3f8;
}

.page-content {
  padding-bottom: 50px;
}

.toolbar-bottom {
  width: 100%;
  margin-top: 5px;
  padding: 0px;
  border-top: 1px solid #c6dcec;
}

.toolbar button,
.toolbar button:disabled,
.toolbar-bottom button,
.toolbar-bottom button:disabled {
  border-right: 1px solid #edf3f8;
}

nz-sider {
  margin-left: 4px;
}

li.ant-menu-item {
  border: 1px solid transparent;
}

li.ant-menu-item.ant-menu-item-selected {
  border: 1px solid #c6dcec;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  transition: border-color 0.75s, background 0.75s,
    padding 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}

input:read-only,
textarea:read-only {
  border: 1px dashed #cfe6f8;
}

nz-date-picker.readonly {
  border: 1px dashed #cfe6f8;
}

nz-date-picker > div > input:read-only {
  border: 1px none red;
}

.ant-tabs-tab a,
.ant-tabs-tab a:active {
  color: black;
}

// ag-grid
.table-row {
  // border-left: 2px solid transparent !important;
}

.search-icon {
  color: silver;
  padding-right: 5px;
}

.search-input-wrapper {
  margin-bottom: 24px;
  border: none;
}

.table-new-row {
  box-shadow: 3px 0px 0px 0px rgba(70, 227, 60, 0.5) inset;
  -webkit-box-shadow: 3px 0px 0px 0px rgba(70, 227, 60, 0.5) inset;
  -moz-box-shadow: 3px 0px 0px 0px rgba(70, 227, 60, 0.5) inset;
}

.table-changed-row {
  box-shadow: 3px 0px 0px 0px sandybrown inset;
  -webkit-box-shadow: 3px 0px 0px 0px sandybrown inset;
  -moz-box-shadow: 3px 0px 0px 0px sandybrown inset;
}

// .ag-theme-alpine {
//   @include ag-theme-alpine(
//     (
//       header-background-color: rgba(237, 243, 248, 0.25),
//       row-hover-color: rgba(0, 183, 255, 0.1),
//       alpine-active-color: #afcadd,
//       checkbox-checked-color: #8bafce,
//       checkbox-unchecked-color: rgba(198, 220, 236, 0.75),
//       checkbox-indeterminate-color: ag-derived(checkbox-checked-color),
//       border-color: #edf3f8,
//       secondary-border-color: #edf3f8,
//       data-color: rgba(0, 0, 0, 0.85),
//       header-height: 36px,
//       selected-row-background-color: rgba(237, 243, 248, 0.9),
//       font-family: (
//         -apple-system,
//         BlinkMacSystemFont,
//         'Segoe UI',
//         Roboto,
//         'Helvetica Neue',
//         Arial,
//         'Noto Sans',
//         sans-serif,
//         'Apple Color Emoji',
//         'Segoe UI Emoji',
//         'Segoe UI Symbol',
//         'Noto Color Emoji',
//       ),
//       font-size: 14px,
//       odd-row-background-color: ag-derived(background-color),
//     )
//   );

//   .ag-header-row {
//     // or write CSS selectors to make customisations beyond what the parameters support
//     color: rgba(0, 0, 0, 0.75);
//     font-weight: 500;
//   }

//   .ag-header-cell {
//     // or write CSS selectors to make customisations beyond what the parameters support
//     border-right: 1px solid ag-param(secondary-border-color);
//   }

//   .ag-header-cell:first-child {
//     // or write CSS selectors to make customisations beyond what the parameters support
//     border-left: 1px solid ag-param(secondary-border-color);
//   }

//   .ag-row {
//     font-size: ag-param(font-size);
//   }

//   .ag-row-selected {
//     -webkit-box-shadow: inset 0px 0px 0px 1px #c6dcec;
//     -moz-box-shadow: inset 0px 0px 0px 1px #c6dcec;
//     box-shadow: inset 0px 0px 0px 1px #c6dcec;
//   }

//   .ag-center-cols-clipper {
//     min-height: 42px !important;
//   }

//   .ag-root-wrapper {
//     border-left: none;
//     border-right: none;
//     border-bottom: none;
//     border-top: 1px solid ag-param(border-color);
//   }
// }
