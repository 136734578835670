// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;

@layout-sider-background: transparent;
@menu-inline-submenu-bg: #edf3f8;
@menu-bg: #edf3f8;
// @menu-inline-submenu-bg:#EDF3F8;
@menu-item-active-border-width: 0px;
@menu-highlight-color: #0082f4;
@menu-item-active-bg: #d7e8f7;
@layout-body-background: #edf3f8;
// @border-color-split: red;
@menu-item-vertical-margin: 0px;
@menu-item-boundary-margin: 0px;
@layout-header-background: #edf3f8;
// @menu-item-height: 10;
.ant-menu-inline {
  border-right-width: 0;
}
.ant-menu-vertical {
  border-right-width: 0;
}

@card-head-background: #edf3f8;
@border-color-split: #c6dcec;
@card-radius: 6px;
@card-padding-base: 0px;
.ant-card-head {
  padding: 0 15px;
}

@btn-text-hover-bg: #d7e8f7;

@page-header-padding-vertical: 0px;
@page-header-padding: 0px;

@tabs-active-color: red;
@tabs-horizontal-padding: 7px 0;
@tabs-bar-margin: 0 0 24px 0;

@table-header-bg: rgba(248, 253, 255, 0.842);
@table-header-sort-bg: @table-header-bg;
@table-header-sort-active-bg: @table-header-bg;
@table-border-color: #edf3f8;

.ant-table-thead > tr > th {
  border-bottom: 1px solid #c6dcec;
  border-top: 1px solid #c6dcec;
}

.ant-table.ant-table-middle .ant-table-thead > tr > th {
  padding: 8px 8px;
}
